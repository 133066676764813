import React from 'react'
import "./blockchainTechs.scss"

import listIcon from "../../../assets/img/listIcon_star.svg"
import BlockchainTechs_bg from "../../../assets/img/BlockchainTechs_bg.png"
import Os_3Phone_icon from "../../../assets/img/os_3Phone_icon.png"
import Hat from "../../../assets/img/Hat 1.png"
import Cube from "../../../assets/img/Cube 1.png"
export default function BlockchainTechs() {

    let BlockchainTechs = [
        {
            id: 1,
            text: 'Help eliminate the distrust from the audience to such platforms, because all transactions become open and provide 100% honesty of actions',
        },
        {
            id: 2,
            text: 'Introducing new opportunities to reward players who become loyal fans of the platform',
        },
        {
            id: 3,
            text: 'More loyal players to invest in the project and watch it grow',
        },
    ]
    let BlockchainItems = [
        {
            id: 1,
            img: Hat,
            title: 'STRATEGY DEVELOPMENT',
            text: 'We deep-dive into our clients brands and use real time market performance analytics in order to build tailored marketing strategies. Our full turnkey',
        },
        {
            id: 2,
            img: Cube,
            title: 'INFLUENCER MARKETING',
            text: 'Our clients get access to our vast influencer network that spans across the globe. We strategically choose influencers that have a track record of high quality conversion rates.',
        },
        {
            id: 3,
            img: Os_3Phone_icon,
            title: 'PAYMENT GUARANTEE',
            text: 'We have spent 5 years fine tuning media buying techniques that allow our clients to benefit from the high quality user traffic on Google, FB, Instagram and youtube. ',
        },
    ]

    return (
        <div className="BlockchainTechs" id="advantages">
            <div className="Blockchain_techs">
                <div className="BlockchainTechs_bg">
                    <img src={BlockchainTechs_bg} alt="" />
                </div>
                <p className="new">NEW TECHNOLOGIES</p>
                <div className="Blockchain_right">
                    <h2>WE ARE THE CREATORS OF CRAZY CASES</h2>
                    {/* <ul>
                        {BlockchainTechs.map((item) => {
                            return (
                                <li key={item.id}>
                                    <div className="icon">
                                        <img src={listIcon} alt="" />
                                    </div>
                                    <div className="text">{item.text}</div>
                                </li>
                            )
                        })}
                    </ul> */}
                    <p>Huge checks and working conditions that allow staff to develop into traffic experts.</p>
                </div>
            </div>
            <div className="Blockchain_items">
                {BlockchainItems.map((item) => {
                    return (
                        <div key={item.id} className="Blockchain_item">
                            <div className="img">
                                {
                                    item.img &&
                                    <img src={item.img} alt="" />
                                }
                            </div>
                            <div className="NoMater_item_bg">
                                    <svg viewBox="0 0 309 388" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_bd_104_26)">
                                            <path d="M14 64C14 36.3858 36.3858 14 64 14H245C272.614 14 295 36.3858 295 64V324C295 351.614 272.614 374 245 374H64C36.3858 374 14 351.614 14 324V64Z" fill="#291D1E" fillOpacity="0.06" shapeRendering="crispEdges" />
                                            <path d="M14.5 64C14.5 36.6619 36.6619 14.5 64 14.5H245C272.338 14.5 294.5 36.6619 294.5 64V324C294.5 351.338 272.338 373.5 245 373.5H64C36.6619 373.5 14.5 351.338 14.5 324V64Z" stroke="url(#paint0_linear_104_26)" shapeRendering="crispEdges" />
                                        </g>
                                        <defs>
                                            <filter id="filter0_bd_104_26" x="-16" y="-16" width="341" height="420" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="15" />
                                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_104_26" />
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                <feOffset />
                                                <feGaussianBlur stdDeviation="7" />
                                                <feComposite in2="hardAlpha" operator="out" />
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.7 0" />
                                                <feBlend mode="normal" in2="effect1_backgroundBlur_104_26" result="effect2_dropShadow_104_26" />
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_104_26" result="shape" />
                                            </filter>
                                            <linearGradient id="paint0_linear_104_26" x1="360" y1="56.5" x2="154.5" y2="374" gradientUnits="userSpaceOnUse">
                                                <stop offset="0.318329" stopColor="#03DFED" />
                                                <stop offset="0.739583" stopColor="white" stopOpacity="0.15" />
                                                <stop offset="1" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    {/* <img src={Rectangle_transparent} alt="" /> */}
                                </div>
                            <h4>{item.title}</h4>
                            <div className="text">{item.text}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}