import React from 'react'
import "./JoinRevolution.scss"

import bg from "../../../assets/img/joinRevolution_bg.png"
import Btn1 from '../../../components/ui/Btn1/Btn1'

export default function JoinRevolution() {
    return (
        <div className="JoinRevolution">
            <div className='JoinRevolutin_text'>
                <h4>JOIN</h4>
                
                <p>THE REVOLUTION</p>
            </div>
            <div className="JoinRevolution_bg">
                <img src={bg} alt="" />
            </div>

            <div className="JoinRevolution_btn">
                <a href="https://affilace.affise.com/v2/sign/in" target="_blank">
                    <button className="newBtn JR_btn">Register</button>
                </a>
                {/* <Btn1
                    text={'coming soon'}
                    func={() => { }}
                    style={{
                        backgroundColor: '#06F0FF',
                    }}
                /> */}
            </div>
        </div>
    )
}