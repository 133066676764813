import React from 'react'
import "./topBanner.scss"
import Btn1 from '../../../components/ui/Btn1/Btn1'

// import TopBannerGif from "../../../assets/img/topBannerGif.gif";
// import TopBannerGif from "../../../assets/img/topBannerGif.mp4";
// import TopBannerGif from "../../../assets/img/topBannerGif.webp";
import TopBannerGif from "../../../assets/img/Model_coleso.png";
// import TopBannerGif from "../../../assets/img/topBannerGif.mkv";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Video from '../../../components/ui/Video/Video';

export default function TopBanner() {
    return (
        <div className="TopBanner" id="topBanner">
            <span className="topBanner_bicas">AFFILACE ECOSYSTEM</span>
            <h1 className="title">
                <span>WE MAKE THE BIGGEST CASH</span>
                <p className="left">On the affiliate </p>
                <p className="right">market in the CIS</p>
            </h1>
            <div className="TopBanner_btns">
                <div className="left">
                    <p>We pour tons of high-quality traffic in Gambling, Datingand other ways</p>
                    {/* <Btn1
                        text={'coming soon'}
                        func={() => {}}
                        style={{
                            backgroundColor: '#06F0FF',
                        }}
                    /> */}
                </div>
                {/* <div className="right">
                    <p>play more - <br/> get more</p>
                    <a href="https://bcss-organization.gitbook.io/whitepaper-bicas-eng/" target="_blank">
                        <Btn1
                            text={'white paper'}
                            func={() => { }}
                            style={{
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                border: '1px solid #FFFFFF',
                            }}
                        />
                    </a>
                    
                </div> */}
            </div>

            <AnchorLink href='#footer' className="TopBanner_scrollDown">SCROLL DOWN</AnchorLink>

            <div className="TopBanner_gif">
                <img src={TopBannerGif} alt="MDN"/>
                {/* <picture>
                    <source srcSet={TopBannerGif} />
                    <img src={TopBannerGif} alt="MDN"/>
                </picture> */}

                {/* <Video src={TopBannerGif}/> */}
                {/* "https://media.w3.org/2010/05/sintel/trailer_hd.mp4" */}

                {/* <video >
                    <source src={TopBannerGif} />
                </video> */}
                {/* <img src={TopBannerGif} alt="" /> */}
            </div>
        </div>
    )
}