import React, { useState } from 'react'
import "./footer.scss"

import Logo from "../../assets/img/Logo.svg"
import twitter from "../../assets/img/twitter.svg"
import inst from "../../assets/img/inst.svg"
import medium from "../../assets/img/medium.svg"


import { Link } from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'

export default function Footer() {

    let [formData, setformData] = useState({})

    let handleSubmit = (e) => {
        e.preventDefault()
        let obj = {
            userEmail: e.target[0].value
        }
        setformData(obj)
    }

    return (
        <div className='Footer' id='footer'>
            <div className='footer_logo'>
            <AnchorLink href='#header' className="logo">
                     <img src={Logo} alt="" />
               </AnchorLink>
                
                    <div className='column_logo'>
                            <p className="bicasEcosystem">Affilace ecosystem</p>
                            <span className="copyright">© 2022 All Rights Reserved</span>
                    </div>
            </div>

            <div className='Gol'>
                <div className='Follow'>
                        <span className="FNavTitle">Follow us:</span>
                        <div className="socials">
                            <a href="https://twitter.com/bcs_token">
                                <div className="footer_social">
                                    <img src={twitter} alt="" />
                                </div>
                            </a>
                            <a href="https://www.instagram.com/bicas_ecosystem/">
                                <div className="footer_social">
                                    <img src={inst} alt="" />
                                </div>
                            </a>
                            <a href="https://medium.com/@BICAS">
                                <div className="footer_social">
                                    <img src={medium} alt="" />
                                </div>
                            </a>
                        </div>
                </div>
            </div>

            <div className='Contact'>
                <span className="FNavTitle">Contact us:</span>
                <a className='email' href="#"><span className=" email">affilaceinfo@gmail.com</span></a>
            </div>
            
        </div>
        // < className="Footer" id="footer">
        //     <div className="column colLogo_mb">
        //         
        //         
        //     </div>

            /* <div className="column colContacts_mb">
                
                
                <div>
                

                </div>
                
                

            </div>
            */
        
    )
}